import Service from '@/service/Service'
import Store from "@/store";

export default {
    getOpenInvoice: async function (userId) {
        const response = await Service.api().get(
            "user/" + userId + "/openInvoice",
        );
        if (response.status === 204) {
            return null
        }
        return response.data;
    },
    deleteItemOnOpenInvoice: async function (userId, itemId) {
        const response = await Service.api().delete(
            "user/" + userId + "/openInvoice/item/" + itemId,
        );
        Store.dispatch('setOpenInvoice', response.data.invoice);
        return response.data;
    },
    setProductQtyToOpenInvoice: async function (userId, productId, qty) {
        const response = await Service.api().put(
            "user/" + userId + "/openInvoice/product/" + productId + "/quantity",
            {
                itemQty: qty
            }
        );
        let openInvoice = response.data.openInvoice;
        if (openInvoice === undefined) {
            openInvoice = null
        }
        Store.dispatch('setOpenInvoice', response.data.invoice);
        if (response.status === 204) {
            return null;
        }
        return response.data;
    },
    confirmOpenInvoice: async function (userId) {
        const response = await Service.api().patch(
            "user/" + userId + "/openInvoice/confirm"
        );
        Store.dispatch('setOpenInvoice', response.data);
        return response.data
    },
    reOpen: async function (userId) {
        const response = await Service.api().patch(
            "member/" + userId + "/openInvoice/reopen"
        );
        await Store.dispatch('setOpenInvoice', response.data);
        return response.data;
    }

}
