<template>
  <div id="app">
    <v-app>
      <v-app-bar
          app
          color="#1B5E20"
          :extension-height="appBarExtensionHeight"
          :src="require('@/assets/legumes_2000_largeur.jpg')"
          fade-img-on-scroll
      >
        <template v-slot:img="{ props }">
          <v-img
              v-bind="props"
              gradient="to bottom right, rgba(27, 94, 32, .9), rgba(0, 0, 0, 0.2)"
          ></v-img>
        </template>
        <div class="d-flex align-center">
          <router-link to="/">
            <v-img
                alt="Logo Baie Des Saveurs"
                class="shrink mr-2"
                contain
                :src="require('@/assets/feuille-seulement-bds.png')"
                transition="scale-transition"
                width="40"
            />
          </router-link>
        </div>
        <v-toolbar-title class="pt-2 ml-1 logo-font" :class="{
          'logo-font-small' : $vuetify.breakpoint.xsOnly
        }">
          <router-link to="/" style="text-decoration: none; color: inherit;" class="white--text">
            Baie des saveurs
          </router-link>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text depressed tile exact-active-class="top-menu-active" to="/comment-ca-marche"
               v-if="$vuetify.breakpoint.mdAndUp"
               class="mr-4 menu-button">
          <span class="white--text">
              {{ $t('app:howTo') }}
            </span>
        </v-btn>
        <v-btn text depressed tile exact-active-class="top-menu-active" to="/produits"
               v-if="$vuetify.breakpoint.mdAndUp"
               class="mr-4 menu-button">
          <span class="white--text">
              {{ $t('app:shop') }}
            </span>
        </v-btn>
        <v-btn text depressed tile exact-active-class="top-menu-active" to="/producteurs"
               v-if="$vuetify.breakpoint.mdAndUp"
               class="mr-4 menu-button">
          <span class="white--text">
              {{ $t('app:producers') }}
            </span>
        </v-btn>
        <v-spacer></v-spacer>
        <div class="text-center" v-if="$store.state.user === null && $vuetify.breakpoint.mdAndUp">
          <v-menu open-on-hover bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text depressed to="/connexion" v-bind="attrs" v-on="on" color="white">
                <v-icon left>login</v-icon>
                <span>
                  {{ $t('app:login') }}
                </span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item to="/inscription">
                <v-list-item-action>
                  <v-icon>how_to_reg</v-icon>
                </v-list-item-action>
                <v-list-item-title>
                  {{ $t('app:register') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-btn
            v-if="$store.state.user === null && $store.state.locale === 'en' && $vuetify.breakpoint.mdAndUp"
            text
            @click="switchLanguage('fr')"
            color="white"
        >
          FR
        </v-btn>
        <v-btn
            v-if="$store.state.user === null && $store.state.locale === 'fr' && $vuetify.breakpoint.mdAndUp"
            text
            @click="switchLanguage('en')"
            color="white"
        >
          EN
        </v-btn>
        <router-link :to="openInvoiceUrl" v-if="this.$store.state.user !== null">
          <v-badge
              class="basket-badge-content" color="white"
              :class="{
                'mr-4' : $vuetify.breakpoint.mdAndUp,
                'mr-2' : $vuetify.breakpoint.smAndDown
              }"
              :content="nbItemsInBasket"
              :value="nbItemsInBasket > 0"
          >
            <v-icon dark>shopping_cart</v-icon>
          </v-badge>
        </router-link>
        <v-menu v-if="$store.state.user === null && $vuetify.breakpoint.smAndDown" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                color="white"
            >
              <v-icon>menu</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item to="/comment-ca-marche">
              <v-list-item-title>
                <v-btn text>{{ $t('app:howTo') }}</v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item to="/produits">
              <v-list-item-title>
                <v-btn text>{{ $t('app:shop') }}</v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item to="/producteurs">
              <v-list-item-title>
                <v-btn text>{{ $t('app:producers') }}</v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item to="/connexion">
              <v-list-item-title>
                <v-btn text>{{ $t('app:login') }}</v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item to="/inscription">
              <v-list-item-title>
                <v-btn text>{{ $t('app:register') }}</v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu bottom offset-y
                v-if="$store.state.user !== null"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                x-large
                v-bind="attrs"
                v-on="on"
                color="white"
            >
              <v-icon color="white">menu</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item to="/comment-ca-marche" v-if="$vuetify.breakpoint.smAndDown">
              <v-list-item-title>
                <v-btn text>{{ $t('app:howTo') }}</v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item to="/produits" v-if="$vuetify.breakpoint.smAndDown">
              <v-list-item-title>
                <v-btn text>{{ $t('app:shop') }}</v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item to="/producteurs" v-if="$vuetify.breakpoint.smAndDown">
              <v-list-item-title>
                <v-btn text>{{ $t('app:producers') }}</v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-divider v-if="$vuetify.breakpoint.smAndDown"></v-divider>
            <v-list-item :to="'/membre/' + $store.state.user.id" v-if="$store.state.user !== null">
              <v-list-item-avatar>
                <v-avatar color="primary" dark size="35">
                  <span class="white--text">
                  {{ initials }}
                  </span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ $t('app:informations') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="switchLanguage()">
              <v-list-item-action>
                <v-icon class="material-icons-outlined">language</v-icon>
              </v-list-item-action>
              <v-list-item-title>
                <span v-if="$store.state.locale === 'en'">Français</span>
                <span v-else>English</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item :to="'/membre/' + $store.state.user.id + '/factures'"
                         v-if="$store.state.user !== null">
              <v-list-item-action>
                <v-icon class="material-icons-outlined">assignment</v-icon>
              </v-list-item-action>
              <v-list-item-title>
                {{ $t('app:historique') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-action>
                <v-icon>exit_to_app</v-icon>
              </v-list-item-action>
              <v-list-item-title>
                {{ $t('app:logout') }}
              </v-list-item-title>
            </v-list-item>
            <v-divider v-if="isAdmin"></v-divider>
            <v-subheader v-if="isAdmin">
              Administration
            </v-subheader>
            <v-list-item :to="'/admin/rapports'" v-if="isAdmin">
              <v-list-item-action>
                <v-icon class="material-icons-outlined">preview</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                {{ $t('app:reports') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="'/admin/produits'" v-if="isAdmin">
              <v-list-item-action>
                <v-icon>local_florist</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                {{ $t('app:products') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="'/admin/factures'" v-if="isAdmin">
              <v-list-item-action>
                <v-icon class="material-icons-outlined">assignment</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                {{ $t('app:invoices') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="'/admin/membres'" v-if="isAdmin">
              <v-list-item-action>
                <v-icon>people</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                {{ $t('app:members') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="'/admin/distributions'" v-if="isAdmin">
              <v-list-item-action>
                <v-icon class="material-icons-outlined">local_shipping</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                {{ $t('app:Distributions') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="'/admin/producteurs'" v-if="isAdmin">
              <v-list-item-action>
                <v-icon>
                  agriculture
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                Producteurs
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="'/admin/categories'" v-if="isAdmin">
              <v-list-item-action>
                <v-icon>category</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                Catégories
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="'/admin/configurations'" v-if="isAdmin">
              <v-list-item-action>
                <v-icon>settings</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                {{ $t('app:configs') }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <template v-slot:extension v-if="shouldShowDistributionNavBar">
          <div style="width:100%;" class="vh-center">
            <v-progress-circular v-if="isLoading" indeterminate :size="60" :width="2"
                                 color="white"></v-progress-circular>
            <Distribution-Nav-Bar v-else
                                  :openOrBeforeDeliveryInvoice="openOrBeforeDeliveryInvoice"
                                  :openDistribution="openDistribution"
                                  :beforeDeliveryDistribution="beforeDeliveryDistribution"
                                  :incomingDistribution="incomingDistribution"
                                  :isOpenInvoiceConfirmed="isOpenInvoiceConfirmed"
                                  :openInvoice="openInvoice"
                                  :openOrBeforeDeliveryDistribution="openOrBeforeDeliveryDistribution"
                                  :hasWrongPickupLocation="hasWrongPickupLocation"
                                  :openInvoiceUrl="openInvoiceUrl"
                                  ref="distributionNavBar"
            ></Distribution-Nav-Bar>
          </div>
        </template>
      </v-app-bar>
      <router-view/>
    </v-app>
    <v-footer
        fixed
        class="font-weight-medium"
        dark
    >
      <v-col
          class="vh-center"
          cols="12"
      >
        {{ new Date().getFullYear() }} —
        <strong>
          Baie des saveurs
        </strong>
      </v-col>
    </v-footer>
    <v-overlay :value="isLoadingFlow">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import LoadingFlow from "@/LoadingFlow";
import I18n from "@/i18n";
import MemberService from "@/service/MemberService";
import DistributionNavBar from "@/components/DistributionNavBar.vue";
import DistributionService from "@/service/DistributionService";
import OpenInvoiceService from "@/service/OpenInvoiceService";
import Pages from "@/Pages";
import Distribution from "@/Distribution";

export default {
  name: "App",
  components: {DistributionNavBar},

  data: function () {
    I18n.i18next.addResources("fr", "app", {
      login: "Se connecter",
      products: "Produits",
      shop: "Marché",
      producers: "Producteurs",
      howTo: "Comment ca marche",
      register: "S'inscrire",
      informations: "Informations",
      logout: "Déconnecter",
      historique: "Historique",
      invoices: "Factures",
      reports: "Rapports",
      configs: "Configurations",
      members: "Membres"
    });
    I18n.i18next.addResources("en", "app", {
      login: "Login",
      products: "Products",
      shop: "Market",
      producers: "Producers",
      howTo: "How it works",
      register: "Register",
      informations: "Informations",
      logout: "Logout",
      historique: "History",
      invoices: "Invoices",
      reports: "Reports",
      configs: "Configurations",
      members: "Membres"
    });
    return {
      isLoadingFlow: false,
      loadingFlows: LoadingFlow.loadingFlows,
      isScrollTop: null,
      isLoading: false,
      openDistribution: null,
      beforeDeliveryDistribution: null,
      incomingDistribution: null,
      openOrBeforeDeliveryInvoice: null,
      openOrBeforeDeliveryDistribution: null,
      appBarExtensionHeight: "140px",
      hasShownWrongPickupLocationDialog: false,
    }
  },
  mounted: async function () {
    this.isLoading = true;
    if (this.$store.state.user) {
      this.refreshMember();
    } else {
      this.refreshContext();
    }
    if (this.$vuetify.breakpoint.smAndDown) {
      this.appBarExtensionHeight = "160px";
    }
    window.addEventListener('scroll', this.onScroll)
    this.onScroll()
  },
  methods: {
    refreshMember: async function () {
      const member = await MemberService.getById(this.$store.state.user.id)
      if (member === false) {
        console.log("erreur de connexion avec le serveur")
        return;
      }
      await this.$store.dispatch('setUser', member)
    },
    refreshContext: async function () {
      console.log("refreshing context")
      this.openDistribution = await DistributionService.getOpen();
      if (this.openDistribution === null) {
        this.beforeDeliveryDistribution = await DistributionService.getBeforeDelivery();
        if (this.beforeDeliveryDistribution !== null) {
          this.openOrBeforeDeliveryDistribution = this.beforeDeliveryDistribution;
          if (this.$store.state.user !== null) {
            this.openOrBeforeDeliveryInvoice = await DistributionService.getInvoiceOfMemberInDistribution(
                this.$store.state.user.id,
                this.beforeDeliveryDistribution.id
            )
          }
        }
        this.incomingDistribution = await DistributionService.getIncoming();
      } else {
        this.openOrBeforeDeliveryDistribution = this.openDistribution;
      }
      let openInvoice = null
      if (this.$store.state.user !== null && this.openDistribution !== null) {
        openInvoice = this.openOrBeforeDeliveryInvoice = await OpenInvoiceService.getOpenInvoice(this.$store.state.user.id)
      }
      await this.$store.dispatch('setOpenInvoice', openInvoice);
      await this.$store.dispatch('setDistributions', {
        open: this.openDistribution,
        beforeDelivery: this.beforeDeliveryDistribution,
        openOrBeforeDelivery: this.openOrBeforeDeliveryDistribution,
        incoming: this.incomingDistribution
      });
      this.isLoading = false;
      await this.$nextTick();
      const distributionNavBar = this.$refs.distributionNavBar;
      const distributionStatusText = distributionNavBar === undefined ? "" : distributionNavBar.$el.innerText;
      await this.$store.dispatch('setDistributionStatusText', distributionStatusText);
    },
    switchLanguage: function (locale) {
      if (locale === undefined) {
        locale = this.$store.state.locale === 'en' ? 'fr' : 'en'
      }
      if (this.$store.state.user !== null) {
        MemberService.updateLocale(
            this.$store.state.user.id,
            locale
        );
      }
      this.$store.dispatch('setLocale', locale)
    },
    logout: function () {
      this.$store.dispatch('setToken', null);
      this.$store.dispatch('setUser', null);
      this.$router.push('/connexion');
    },
    onScroll() {
      this.isScrollTop = document.documentElement.scrollTop > 30 ? false : true
    }
  },
  computed: {
    shouldShowDistributionNavBar: function () {
      return this.isScrollTop && Pages.loggedOutOnlyPages.indexOf(this.$route.name) === -1;
    },
    initials: function () {
      const fullname = this.$store.state.user.firstname + " " + this.$store.state.user.lastname;
      return fullname.split(" ").map((n) => n[0]).join("");
    },
    isAdmin: function () {
      return this.$store.state.user !== null && this.$store.state.user.isAdmin;
    },
    user: function () {
      return this.$store.state.user
    },
    openInvoice: function () {
      return this.$store.state.openInvoice
    },
    isOpenInvoiceConfirmed: function () {
      return this.$store.state.openInvoice !== null && this.$store.state.openInvoice.status === 'confirmed';
    },
    openInvoiceUrl: function () {
      if (this.$store.state.user === null) {
        return "/connexion"
      }
      return "/membre/" + this.$store.state.user.id + "/bondecommande/edit";
    },
    hasWrongPickupLocation: function () {
      if (this.$store.state.user === null) {
        return false
      }
      if (this.openOrBeforeDeliveryDistribution === null) {
        return false;
      }
      if (this.openOrBeforeDeliveryInvoice === null) {
        return false;
      }
      return !Distribution.isMemberPickupLocationOk(
          this.openOrBeforeDeliveryDistribution,
          this.$store.state.user,
          this.openOrBeforeDeliveryInvoice
      )
    },
    nbItemsInBasket: function () {
      if (this.openInvoice === null || this.openDistribution === null || this.isOpenInvoiceConfirmed || this.hasWrongPickupLocation) {
        return 0;
      }
      const nbDifferentItems = this.openInvoice.InvoicesItems.length;
      if (nbDifferentItems === 1) {
        return this.openInvoice.InvoicesItems.reduce((sum, item) => {
          return sum + item.productQty
        }, 0)
      }
      return nbDifferentItems
    },
  },
  watch: {
    loadingFlows: function () {
      this.isLoadingFlow = this.loadingFlows.length > 0;
    },
    shouldShowDistributionNavBar: function () {
      this.$store.dispatch('setIsAppBarExtended', this.shouldShowDistributionNavBar)
    },
    user: function () {
      this.refreshContext()
    },
    openInvoice: function () {
      if (this.openInvoice !== null) {
        this.openOrBeforeDeliveryInvoice = this.openInvoice
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>


<style lang="scss">
$primary: #1B5E20;
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.blue-title {
  color: #17347c !important;
}

.green-text {
  color: $primary !important;
}

.vh-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-center {
  display: flex;
  align-items: center;
}

.v-bottom {
  display: flex;
  align-items: end;
}

.top-menu-active, .menu-button.v-btn:hover {
  &:before {
    //no background
    opacity: 0 !important;
  }

  border-bottom: 2px solid whitesmoke;
  font-weight: bold;
}

.material-symbols-outlined {
  font-variation-settings: 'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

@import url('https://fonts.googleapis.com/css2?family=Slabo+27px&display=swap');
.logo-font {
  font-family: 'Slabo 27px', serif;
  text-decoration: none !important;
  font-size: 35px;
  align-self: inherit !important;
  float: right;
}

.logo-font-small {
  font-size: 25px;
}

.secondRow {
  width: 100%;
  background-color: #2c3e50;
  color: whitesmoke;
}

.distributionMenu {
  box-shadow: none;
  transform: translate3d(0, -100%, 0);
}

.green-border {
  border: 1px solid $primary;
}

.bigger-hint {
  .v-text-field__details, .v-messages {
    min-height: 20px;
  }

  .v-messages__message {
    font-size: 16px;
    line-height: inherit;
  }
}

.gray-background {
  background-color: #eeeeee
}

.basket-badge-content {
  .v-badge__badge {
    color: $primary !important;
    font-weight: bold;
  }
}
</style>
