const Pages = {
    loggedOutOnlyPages: [
        'Login',
        'Register',
        'ForgotPassword',
        'ChangePassword',
        'SendChangePasswordEmail'
    ]
}
Pages.loggedOutAllowedPages = Pages.loggedOutOnlyPages.concat([
    'LandingPage',
    'HowItWorksPage',
    'ProducersPage',
    'Products',
    'Product',
    'ProductByCategory'
])
export default Pages
