import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
// import 'material-design-icons-iconfont/dist/material-design-icons.css'
// import 'material-icons/iconfont/material-icons.css';
import 'material-icons/iconfont/filled.css';
import 'material-icons/iconfont/outlined.css';
import DateUtil from "@/dateUtil"
import CurrencyFilter from "@/currencyFilter"
import I18n from "@/i18n"
import VueNumericInput from 'vue-numeric-input'

Vue.config.productionTip = false
CurrencyFilter.setup();
const vueI18nExt = I18n.setup();
DateUtil.setup();
new Vue({
    i18n: vueI18nExt,
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
Vue.prototype.$url = window.location.origin;
Vue.use(VueNumericInput)
