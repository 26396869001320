import Service from '@/service/Service'
import Store from "@/store";

const MemberService = {
    search: async function (searchText) {
        const response = await Service.api().get("/member/search?searchText=" + searchText);
        return response.data;
    },

    getById: async function (id) {
        try {
            const response = await Service.api().get("member/" + id)
            return response.data
        } catch (error) {
            if (error.response.data.errorName === "needToBeAuthenticated") {
                await Store.dispatch('setToken', null);
                return null
            }
            return false;
        }
    },
    update: async function (member) {
        const response = await Service.api().put(
            "member/" + member.id,
            member
        );
        return response.data;
    },
    updateLocale: async function (memberId, locale) {
        const response = await Service.api().patch(
            `/member/${memberId}/locale`,
            {
                locale: locale
            }
        );
        return response.data;
    },
    updateUser: async function (user) {
        const response = await Service.api().put(
            "user/" + user.id,
            user
        );
        return response.data;
    },
    invoiceCount: async function (userId) {
        const response = await Service.api().get(
            '/user/' + userId + '/invoice/count'
        );
        return response.data;
    },
    setPickupLocation: async function (member, newPickupLocation) {
        const response = await Service.api().patch(
            "user/" + member.id + '/' + 'pickupLocation/' + newPickupLocation.id,
        );
        return response.data;
    },
    setInvoicePickupLocation: async function (userId, invoiceId, newPickupLocation) {
        const response = await Service.api().patch(
            "user/" + userId + '/invoice/' + invoiceId + '/pickupLocation/' + newPickupLocation,
        );
        return response.data
    },
    getInvoiceById: async function (userId, invoiceId) {
        const response = await Service.api().get(
            "user/" + userId + '/invoice/' + invoiceId
        );
        return response.data
    },
    deleteInvoice: async function (userId, invoiceId) {
        const response = await Service.api().delete(
            "member/" + userId + '/openInvoice/' + invoiceId
        );
        if (!await response.data.err) {
            Store.commit('setOpenInvoice', null);
        }
        return response.data
    },
    listAdmins: async function () {
        const response = await Service.api().get(
            "member/admin"
        );
        return response.data.map(MemberService._format)
    },
    getAll: async function (offset, itemsPerPage) {
        const response = await Service.api().get(
            "/user/all?offset=" + offset + "&limit=" + itemsPerPage
        );
        return response.data
    },
    getNbMembers: async function () {
        const response = await Service.api().get(
            "/user/count"
        );
        return response.data.totalMembers
    },
    delete: async function (userId) {
        const response = await Service.api().delete("user/" + userId)
        return response.data;
    },
    _format: function (member) {
        member.fullname = member.firstname + " " + member.lastname
        return member;
    }
}
export default MemberService
