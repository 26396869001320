import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'LandingPage',
        component: () => import('../views/LandingPage.vue')
    },
    {
        path: '/comment-ca-marche',
        name: 'HowItWorksPage',
        component: () => import('../views/HowItWorksPage.vue')
    },
    {
        path: '/producteurs',
        name: 'ProducersPage',
        component: () => import('../views/ProducersPage.vue')
    },
    {
        path: '/change-password/:token',
        name: 'ChangePassword',
        component: () => import('../views/ChangePassword.vue')
    },
    {
        path: '/send-change-password-email',
        name: 'SendChangePasswordEmail',
        component: () => import('../views/SendChangePasswordEmail.vue')
    },
    {
        path: '/connexion',
        name: 'Login',
        component: () => import('../views/LoginPage.vue')
    },
    {
        path: '/inscription',
        name: 'Register',
        component: () => import('../views/MemberRegistration.vue')
    },
    {
        path: '/membre/:memberId/bondecommande/edit',
        name: 'OpenInvoiceEdit',
        component: () => import('../views/OpenInvoiceEdit.vue')
    },
    {
        path: '/membre/:memberId',
        name: 'MemberEdit',
        component: () => import('../views/MemberEdit.vue')
    },
    {
        path: '/membre/:memberId/factures',
        name: 'MemberInvoiceList',
        component: () => import('../views/MemberInvoiceList.vue')
    },
    {
        path: '/membre/:memberId/factures/:invoiceId',
        name: 'SingleInvoice',
        component: () => import('../views/MemberSingleInvoice.vue'),
        props: true
    },
    {
        path: '/produits',
        name: 'Products',
        component: () => import('../views/ProductsPage.vue')
    },
    {
        path: '/produits/categorie/:categoryId',
        name: 'ProductByCategory',
        component: () => import('../views/ProductsPage.vue')
    },
    {
        path: '/produit/:productId',
        name: 'Product',
        component: () => import('../views/ProductPage.vue'),
        props: true
    },
    {
        path: '/admin/membres',
        name: 'AdminMembers',
        component: () => import('../views/admin/UsersAdmin.vue'),
        props: true
    },
    {
        path: '/admin/factures',
        name: 'AdminInvoices',
        component: () => import('../views/admin/InvoicesAdmin.vue'),
        props: true
    },
    {
        path: '/admin/factures/en-cours',
        name: 'AdminInvoicesCurrent',
        component: () => import('../views/admin/InvoicesAdmin.vue'),
    },
    {
        path: '/admin/factures/archive',
        name: 'AdminInvoicesArchive',
        component: () => import('../views/admin/InvoicesAdmin.vue'),
    },
    {
        path: '/admin/distributions',
        name: 'adminDistributions',
        component: () => import('../views/admin/DistributionsAdmin.vue'),
        props: true
    },
    {
        path: '/admin/categories',
        name: 'CategoriesAdmin',
        component: () => import('../views/admin/CategoriesAdmin.vue'),
        props: true
    },
    {
        path: '/admin/pointsdechute',
        name: 'adminPickupLocation',
        component: () => import('../views/admin/DistributionsAdmin.vue'),
        props: true
    },
    {
        path: '/admin/rapports',
        name: 'ReportsPage',
        component: () => import('../views/admin/ReportsPage.vue')
    },
    {
        path: '/admin/rapports/distribution',
        name: 'DistributionReport',
        component: () => import('../views/admin/ReportsPage.vue')
    },
    {
        path: '/admin/rapports/point-de-chute',
        name: 'PickupLocationReport',
        component: () => import('../views/admin/ReportsPage.vue')
    },
    {
        path: '/admin/rapports/abonnement',
        name: 'MembershipReport',
        component: () => import('../views/admin/ReportsPage.vue')
    },
    {
        path: '/admin/rapports/ventes',
        name: 'SalesReport',
        component: () => import('../views/admin/ReportsPage.vue')
    },
    {
        path: '/admin/produits',
        name: 'ProductsAdmin',
        component: () => import('../views/admin/ProductsAdmin.vue'),
        props: true
    },
    {
        path: '/admin/producteurs',
        name: 'ProducersAdmin',
        component: () => import('../views/admin/ProducersAdmin.vue'),
        props: true
    },
    {
        path: '/admin/configurations',
        name: 'adminConfigurations',
        component: () => import('../views/admin/Configurations.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
