import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistedState from 'vuex-persistedstate'
import Distribution from "@/Distribution";

Vue.use(Vuex)


Object.defineProperty(Vue.prototype, 'hasWrongPickupLocation', {
    get() {
        if (this.$store.state.user === null) {
            return false
        }
        if (this.openOrBeforeDeliveryDistribution === null) {
            return false;
        }
        if (this.openOrBeforeDeliveryInvoice === null) {
            return false;
        }
        return !Distribution.isMemberPickupLocationOk(
            this.openOrBeforeDeliveryDistribution,
            this.$store.state.user,
            this.openOrBeforeDeliveryInvoice
        )
    }
})

export default new Vuex.Store({
    plugins: [
        VuexPersistedState({
            paths: [
                "token",
                "user",
                "locale",
                "isProductsListView"
            ]
        })
    ],
    state: {
        token: null,
        user: null,
        locale: 'fr',
        openInvoice: null,
        distributions: null,
        isAppBarExtended: false,
        isProductsListView: false,
        distributionStatusText: ''
    },
    mutations: {
        setToken: function (state, token) {
            state.token = token
            if (!token) {
                state.user = null;
            }
        },
        setUser: function (state, user) {
            state.user = user;
        },
        setLocale: function (state, locale) {
            if (state.locale === locale) {
                return
            }
            state.locale = locale;
            location.reload();
        },
        setOpenInvoice: function (state, openInvoice) {
            state.openInvoice = openInvoice
        },
        setDistributions: function (state, distributions) {
            state.distributions = distributions
        },
        setIsAppBarExtended: function (state, isAppBarExtended) {
            state.isAppBarExtended = isAppBarExtended
        },
        setIsProductsListView: function (state, isProductsListView) {
            state.isProductsListView = isProductsListView
        },
        setDistributionStatusText: function (state, text) {
            state.distributionStatusText = text
        }
    },
    actions: {
        setToken: function (action, token) {
            action.commit('setToken', token)
        },
        setUser: function (action, user) {
            action.commit('setUser', user)
        },
        setLocale: function (action, locale) {
            action.commit('setLocale', locale)
        },
        setOpenInvoice: function (action, openInvoice) {
            if (openInvoice === undefined) {
                openInvoice = null
            }
            action.commit('setOpenInvoice', openInvoice)
        },
        setDistributions: function (action, distributions) {
            if (distributions === undefined || distributions === null) {
                distributions = {}
            }
            action.commit('setDistributions', distributions)
        },
        setIsAppBarExtended: function (action, isAppBarExtended) {
            action.commit('setIsAppBarExtended', isAppBarExtended)
        },
        setIsProductsListView: function (action, isProductsListView) {
            action.commit('setIsProductsListView', isProductsListView)
        },
        setDistributionStatusText: function (action, text) {
            action.commit('setDistributionStatusText', text)
        }
    }
})
