import {format, parse} from 'date-fns'
import {fr} from 'date-fns/locale'
import Vue from 'vue'
import I18n from "@/i18n";

const dateUtil = {
    setup: function () {
        Vue.filter('date', function (value) {
            if (value) {
                return format(
                    new Date(String(value)),
                    'd MMMM yyyy, HH:mm:ss',
                    {
                        locale: fr
                    }
                )
            }
        })
        Vue.filter('time', function (timeString) {
            if (!timeString) {
                return ''
            }
            const parsedTime = parse(timeString, 'HH:mm:ss', new Date())
            return format(parsedTime, 'h:mm a')
        })
        Vue.filter('dayDate', dateUtil.getDayDate)
        Vue.filter('dayNoYearDate', dateUtil.getDayNoYearDate)
        Vue.filter('prettyDate', function (value) {
            if (!value) {
                return
            }
            const options = {
                day: "numeric",
                weekday: "long",
                month: "long",
            };
            return new Intl.DateTimeFormat(I18n.i18next.language, options).format(
                new Date(value)
            );
        })
        Vue.filter('prettyDateYear', function (value) {
            if (!value) {
                return
            }
            const options = {
                day: "numeric",
                weekday: "long",
                month: "long",
                year: 'numeric'
            };
            return new Intl.DateTimeFormat(I18n.i18next.language, options).format(
                new Date(value)
            );
        })
    },
    getDayDate: function (value) {
        if (value) {
            return format(
                new Date(String(value)),
                'd MMMM yyyy',
                {
                    locale: fr
                }
            )
        }
    },
    getDayNoYearDate: function (value) {
        if (value) {
            return format(
                new Date(String(value)),
                'd MMMM',
                {
                    locale: fr
                }
            )
        }
    },
    formatForDatePicker: function (value) {
        if (value) {
            return format(
                new Date(String(value)),
                'yyyy-MM-dd',
                {
                    locale: fr
                }
            )
        }
    },
    areDatesSameDay: function (d1, d2) {
        return d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getUTCDate() === d2.getUTCDate();
    },
    inputFormatted: function (date) {
        return format(date, "yyyy-MM-dd")
    },
    inputFormattedToDate: function (inputFormatted) {
        return new Date(
            inputFormatted.replaceAll("-", "/")
        )
    }
}

export default dateUtil
