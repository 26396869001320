import Service from '@/service/Service'
import {endOfDay, startOfDay} from "date-fns";
import dateUtil from "@/dateUtil";

const DistributionService = {
    update: async function (distribution) {
        distribution.orderOpenDate = startOfDay(distribution.orderOpenDate);
        distribution.orderCloseDate = endOfDay(distribution.orderCloseDate);
        return Service.api().put(
            "/distribution/" + distribution.id,
            distribution
        );
    },
    create: async function (distribution) {
        distribution.orderOpenDate = startOfDay(distribution.orderOpenDate);
        distribution.orderCloseDate = endOfDay(distribution.orderCloseDate);
        const response = await Service.api().post(
            "/distribution/",
            distribution
        );
        return DistributionService._format(response.data);
    },
    getLightWeightList: async function () {
        const response = await Service.api().get(
            "/distribution/lightweight-list"
        )
        return response.data.map(DistributionService._format)
    },
    list: async function () {
        const response = await Service.api().get(
            "/distributions"
        )
        return response.data.map(DistributionService._format)
    },
    getById: async function (id) {
        const response = await Service.api().get(
            "/distribution/" + id
        )
        return DistributionService._format(response.data)
    },
    getOpen: async function () {
        const response = await Service.api().get(
            "/distribution/open"
        )
        if (response.status === 204) {
            return null
        }
        return DistributionService._format(response.data)
    },
    getBeforeDelivery: async function () {
        const response = await Service.api().get(
            "/distribution/before-delivery"
        )
        if (response.status === 204) {
            return null
        }
        return DistributionService._format(response.data)
    },
    getIncoming: async function () {
        const response = await Service.api().get(
            "/distribution/incoming"
        )
        if (response.status === 204) {
            return null
        }
        return DistributionService._format(response.data)
    },
    getOpenOrBeforeDelivery: async function () {
        const response = await Service.api().get(
            "/distribution/open-or-before-delivery"
        )
        if (response.status === 204) {
            return null
        }
        return DistributionService._format(response.data)
    },
    getPrevious: async function (distributionId) {
        const response = await Service.api().get(
            "/distribution/" + distributionId + "/previous"
        )
        if (response.status === 204) {
            return null
        }
        return DistributionService._format(response.data)
    },
    getNext: async function (distributionId) {
        const response = await Service.api().get(
            "/distribution/" + distributionId + "/next"
        )
        if (response.status === 204) {
            return null
        }
        return DistributionService._format(response.data)
    },
    getLatest: async function () {
        const response = await Service.api().get(
            "/distribution/latest"
        )
        if (response.status === 204) {
            return null
        }
        return DistributionService._format(response.data)
    },
    getInvoiceOfMemberInDistribution: async function (memberId, distributionId) {
        const response = await Service.api().get(
            `/member/${memberId}/distribution/${distributionId}/invoice`
        )
        if (response.status === 204) {
            return null
        }
        return DistributionService._format(response.data)
    },
    _format: function (distribution) {
        if (distribution.orderOpenDate) {
            distribution.orderOpenDate = new Date(distribution.orderOpenDate)
            distribution.orderOpenDateFormattedForInput = dateUtil.inputFormatted(
                distribution.orderOpenDate
            )
        }
        if (distribution.orderCloseDate) {
            distribution.orderCloseDate = new Date(distribution.orderCloseDate)
            distribution.orderCloseDateFormattedForInput = dateUtil.inputFormatted(
                distribution.orderCloseDate
            )
        }

        if (distribution.deliveryDate) {
            distribution.deliveryDate = new Date(distribution.deliveryDate)
            distribution.deliveryDateFormattedForInput = dateUtil.inputFormatted(
                distribution.deliveryDate
            )
        }
        return distribution;
    }
}
export default DistributionService

