<template>
  <v-alert
      shaped
      colored-border
      style="background-color:rgba(255,255,255,1)"
  >
    <div v-if="isOpenInvoiceConfirmed && !hasWrongPickupLocation" class="body-1">
      <router-link :to="openInvoiceUrl">
        {{ $t('status:seeYourOrder') }}
      </router-link>
    </div>
    <div v-if="isItTimeToOrder && !openInvoice" class="body-1">
      {{ $t('status:timeToOrder') }}
      <router-link v-if="$store.state.user === null" to="/connexion">
        {{ $t('status:connect') }}
      </router-link>
      <span v-if="$store.state.user !== null">
                {{ $t('status:until') }}
                <strong>
                    {{ openDistribution.orderCloseDate | prettyDate }}
                </strong>
            </span>
    </div>
    <div v-if="openInvoice !== null && openDistribution !== null && !isOpenInvoiceConfirmed && !hasWrongPickupLocation"
         class="body-1">
      <v-btn text :to="openInvoiceUrl" outlined>
        <v-icon color="primary" left>shopping_cart</v-icon>
        {{ $t('status:yourBasket') }}
        {{ totalInStore | currency }}
      </v-btn>
      <v-divider vertical></v-divider>
      <span class="">
                {{ $t('status:orderUntil') }}
                <strong>
                    {{ openDistribution.orderCloseDate | prettyDate }}
                </strong>
            </span>
    </div>
    <div v-if="isWaitingForDistribution" class="body-1">
      <router-link :to="archivedInvoiceUrl">{{ $t('status:yourProducts') }}</router-link>
      {{ $t('status:willBeDelivered') }}
      <strong>
        {{ beforeDeliveryDistribution.deliveryDate | prettyDate }}
      </strong>
      {{ $t('status:at') }}
      <span>
          {{ pickupLocation.name }}
        </span>
    </div>
    <div v-if="shouldShowIncoming" class="body-1">
      {{ $t('status:incomingOrder') }}
      <strong>
        {{ incomingDistribution.orderOpenDate | prettyDate }}.
      </strong>
      <div v-if="$store.state.user === null" class="mt-2">
        <router-link to="/inscription">{{ $t('status:makeSureToRegister1') }}</router-link>
        {{ $t('status:makeSureToRegister2') }}
      </div>
    </div>
    <div v-if="hasWrongPickupLocation" class="body-1">
      <v-alert dense class="pa-0 ma-0" type="error" outlined prominent>
        <v-row align="center">
          <v-col class="grow">
            {{ $t('status:unavailablePickupLocation') }}
          </v-col>
          <v-col class="shrink">
            <v-btn color="error" text :to=urlToModifyWrongPickupLocation>
              <v-icon left>edit</v-icon>
              {{ $t('modify') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </div>
    <div v-if="noRelevantDistribution" class="body-1">
      {{ $t('status:noRelevantDistribution') }}
    </div>
    <div v-if="isTooLateToOrder" class="body-1 mt-2">
      {{ $t('status:tooLate') }}
      <strong v-if="openOrBeforeDeliveryDistribution !== null">
        {{ openOrBeforeDeliveryDistribution.orderCloseDate | prettyDate }}
      </strong>
    </div>
    <div v-if="openOrBeforeDeliveryDistribution !== null && !isWaitingForDistribution" class="body-1">
      <v-row>
        <v-col cols="12" class="vh-center">
          <v-spacer></v-spacer>
          <v-divider class="mt-2 mb-2"></v-divider>
          <v-spacer></v-spacer>
        </v-col>
      </v-row>
      <!--            <v-icon class="material-icons-outlined" left>local_shipping</v-icon>-->
      {{ $t('status:delivery') }}:
      <strong>
        {{ openOrBeforeDeliveryDistribution.deliveryDate | prettyDate }}
      </strong>
    </div>
  </v-alert>
</template>
<script>

import I18n from "@/i18n";

export default {
  name: "DistributionNavBar",
  props: [
    'openOrBeforeDeliveryInvoice',
    'openDistribution',
    'beforeDeliveryDistribution',
    'incomingDistribution',
    'isOpenInvoiceConfirmed',
    'openInvoice',
    'hasWrongPickupLocation',
    'openOrBeforeDeliveryDistribution',
    'openInvoiceUrl'
  ],
  data: function () {
    I18n.i18next.addResources("en", "status", {
      timeToOrder: "It's time to order !",
      total: "Total",
      orderUntil: "Order until",
      until: "Until",
      register: 'register',
      connect:"Login",
      details: "See details",
      dropPoint: "Drop point",
      order: "Order",
      orderOf: "Your order of",
      deliverOn: "will be at",
      from: "from",
      to: "a",
      at: "at",
      incomingOrder: "You will be able to order starting",
      makeSureToRegister1: "Register",
      makeSureToRegister2: "if you never ordered on Baie Des Saveurs.",
      yourProducts: "Your order",
      willBeDelivered: "will be delivered on",
      noRelevantDistribution: "No scheduled distribution",
      yourBasket: "Panier",
      seeYourOrder: "Your order is confirmed",
      delivery: "For delivery",
      tooLate: "The order date was over the",
      unavailablePickupLocation: "Your pickup location is unavailable !"
    });
    I18n.i18next.addResources("fr", "status", {
      timeToOrder: "C'est le moment de commander !",
      register: 'inscrivez-vous',
      connect:"Se connecter",
      details: "Voir détails",
      total: "Total",
      orderUntil: "Commandez jusqu'au",
      until: "Jusqu'au",
      dropPoint: "Point de chute",
      order: "Commande",
      orderOf: "Votre commande de",
      deliverOn: "sera livré le",
      from: "de",
      to: "to",
      at: "à",
      incomingOrder: "Vous pourrez commander à partir du",
      makeSureToRegister1: "Inscrivez-vous",
      makeSureToRegister2: "si vous n'avez jamais commandé sur Baie Des Saveurs.",
      yourProducts: "Votre commande",
      willBeDelivered: "sera livrée le",
      noRelevantDistribution: "Aucune distribution de planifiée",
      yourBasket: "Panier",
      seeYourOrder: "Votre commande est confirmée",
      delivery: "Pour livraison",
      tooLate: "La date de commande s'est terminée le",
      unavailablePickupLocation: "Votre point de chute est indisponible !"
    });
    return {
      userId: null
    }
  },
  mounted: async function () {
    if (this.$store.state?.user?.id) {
      this.userId = this.$store.state.user.id
    }
  },
  computed: {
    totalInStore() {
      if (this.$store.state.openInvoice) {
        return this.$store.state.openInvoice.total
      }
      return 0;
    },
    pickupLocation: function () {
      return this.$store.state.user === null ? null : this.$store.state.user.PickupLocation
    },
    isItTimeToOrder() {
      const now = new Date();
      return !this.hasWrongPickupLocation && this.openDistribution !== null &&
          now > this.openDistribution.orderOpenDate && now < this.openDistribution.orderCloseDate
    },
    isWaitingForDistribution() {
      return !this.hasWrongPickupLocation && this.$store.state.user !== null && this.beforeDeliveryDistribution !== null && this.openOrBeforeDeliveryInvoice !== null
    },
    isTooLateToOrder() {
      return this.$store.state.user !== null && this.beforeDeliveryDistribution !== null && this.openOrBeforeDeliveryInvoice === null && !this.hasWrongPickupLocation
    },
    shouldShowIncoming() {
      return !this.isWaitingForDistribution && this.incomingDistribution !== null
    },
    noRelevantDistribution() {
      return this.openDistribution === null && this.beforeDeliveryDistribution === null && this.incomingDistribution === null
    },
    urlToModifyWrongPickupLocation: function () {
      return this.$store.state.openInvoice === null ?
          this.archivedInvoiceUrl :
          this.openInvoiceUrl

    },
    archivedInvoiceUrl: function () {
      return "/membre/" + this.userId + "/factures/" + this.openOrBeforeDeliveryInvoice.id
    }
  }
}
</script>

<style scoped>

</style>
